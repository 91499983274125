@use './mixins';

.datalist {
    @include mixins.list-reset;
    padding: 0.4em;
}

.datalist > * + * {
	margin-block-start: 0.5em;
}

.datalist__item {
	background-color: var(--bubble);
	border-radius: 0.4rem;
    font-size: 0.9rem;
    line-height: 1;
    display: grid;
    gap: 0 1rem;
    align-items: center;
    padding: 0.8em 0.7em;
}

.weightlist__item {
    grid-template-areas:
        "date date date"
        "note note bmi"
		"kg actions actions";
    grid-template-columns: 140px 1fr auto;
	row-gap: 0.5em;
    align-content: center;
}

.weightlist__date {
    grid-area: date;
	font-size: 1.05rem;
	margin-bottom: 0.4em;
}

.weightlist__amount {
    --triangle-w: 0.45rem;
    --triangle-h: 0.65rem;

    grid-area: kg;
	align-self: end;
    display: flex;
    align-items: center;
    column-gap: 0.8em;

    &::before {
        content: '';
        display: inline-block;
    }
}

.weightlist__bmi {
	grid-area: bmi;
	align-self: start;
}

.weightlist__amount--up::before,
.weightlist__amount--down::before {
    width: 0;
    height: 0;
    border-left: var(--triangle-w) solid transparent;
    border-right: var(--triangle-w) solid transparent;
}

.weightlist__amount--up::before {
    border-bottom: var(--triangle-h) solid var(--white);
}

.weightlist__amount--down::before {
    border-top: var(--triangle-h) solid var(--white);
}

.weightlist__amount--idle::before {
    background-color: var(--white);
    border-radius: 2px;
    width: 0.8rem;
    height: 1px;
}

.weightlist__note {
    grid-area: note;
	line-height: 1.2;
    margin: 0;
}

.weightlist__actions {
	grid-area: actions;
	justify-self: end;
	display: flex;
	column-gap: 1em;
	margin-block-start: 1em;
}

.moodlist__item {
	grid-template-areas:
		"date date"
		"content delete";
	row-gap: 0.8rem;
}

.moodlist__item .btn--delete {
	grid-area: delete;
}

.mood__content {
	grid-area: content;
	display: flex;
	column-gap: 1em;
	align-items: center;
}

.mood__date {
	font-size: 1.05rem;
	grid-area: date;
}

.mood__icon {
    --size: 28px;
    --size-svg: 26px;

    background-color: var(--lighter-gray);
    border-radius: 50%;
    display: grid;
    place-content: center;
    width: var(--size);
    aspect-ratio: 1;
}

.mood__icon svg {
    fill: var(--black);
    width: var(--size-svg);
    vertical-align: middle;
    padding: 0.125rem;
}

.moodlist__item .btn--delete {
	justify-self: end;
}
