@use './mixins';

.nav {
	--motion: cubic-bezier(0.4, 0, 0.2, 1);
	--_timer: 420ms;
	--_timer-delay: 100ms;
	--_timer-total: calc((var(--_timer) + var(--_timer-delay)));
}

.nav__btn {
	backdrop-filter: blur(12px);
	background-color: var(--glass-btn);
	border-radius: 50vw;
	color: var(--glass-btn-color);
	font-size: 0.8rem;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	display: inline-block;
	padding: 0.6875rem 0.8rem;
	position: relative;
	z-index: 20;
}

.nav__main {
	backdrop-filter: blur(32px);
	background-color: var(--glass-bg);
	color: var(--lighter-gray);
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0;
	bottom: 0;
	visibility: hidden;
	opacity: 0;
	transition:
		opacity var(--_timer) ease-in-out,
		visibility 0ms var(--_timer-total);
	will-change: visibility;
	pointer-events: none;
}

.is-open.nav__main {
	transition:
		visibility 0ms,
		opacity (var(--_timer)) ease-in-out;
	visibility: visible;
	opacity: 1;
	pointer-events: auto;
}

.nav__inner {
	@include mixins.hide-scrollbar;

	background-color: var(--bubble);
	border-radius: 2rem;
	width: calc(100% - 1.7rem);
	max-height: 73vh;
	padding: 0.5rem;
	position: relative;
	opacity: 0;
	transform: translateY(2.5rem);
	transition:
		transform var(--_timer) ease-in-out,
		opacity var(--_timer) ease-in-out;
	overflow-y: scroll;
	overscroll-behavior: contain;
}

.is-open .nav__inner {
	opacity: 1;
	transform: translateY(0);
	transition-delay: 150ms;
}

.nav__head-title {
	font-size: 1.125rem;
	font-weight: 600;
	text-align: center;
	margin-top: 0.5rem;
}

.nav__list {
	@include mixins.list-reset;

	> * {
		padding: 0.3em 0;
		white-space: nowrap;
	}
}

.nav__list--top {
	background-color: var(--page-bg);
	border-radius: 1rem;
	margin-top: 1rem;
}

.nav__list--top,
.nav__section-list {
	padding: 0.8rem;
}

.nav__list--bottom {
	background-color: var(--bubble);
	display: flex;
	column-gap: 1.3rem;
	align-items: center;
	justify-content: center;
	text-align: center;
	width: 100%;
	position: sticky;
	left: 0;
	bottom: 0;
	box-shadow: 0 0.5rem 0 0 var(--bubble);
}

.nav__list-title {
	color: var(--light-gray);
	font-size: 0.7rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 0.2em;
}

.nav__item-link,
.nav__item-link--bottom {
	font-family: sans-serif;
	font-size: 0.9rem;
	line-height: 1;
	letter-spacing: 0.02em;
}

.nav__item-link:not(.nav__item-link--bottom) {
	font-weight: 600;
}

.nav__item-link.is-selected {
	text-decoration: underline;
}
