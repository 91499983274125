@use 'mixins';

/*
 * Medicine cabinet
 */
.cabinet {
    input[type=number]::-webkit-outer-spin-button,
    input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type=number] {
        appearance: auto;
    }
}

.cabinet-date__input::-webkit-calendar-picker-indicator {
    display: none;
}

.cabinet__list {
	@include mixins.list-reset;
	margin-bottom: 2rem;

	> * + * {
		margin-top: 1.6rem;
	}
}

.cabinet__item {
	background-color: var(--bubble);
	border-radius: 2.3rem;
	font-size: 0.86rem;
	font-weight: 700;
	display: grid;
	grid-template-areas:
		"checkbox title title"
		"checkbox date dose";
	grid-template-columns: max-content minmax(1em, 1fr) minmax(1em, 1fr);
	align-items: center;
	column-gap: 0.5rem;
	padding: 2rem 1.5rem;
	position: relative;

	&::before,
	&::after {
		content: '';
		background-color: var(--medium-light-gray);
		border-radius: inherit;
		position: absolute;
		inset: 0;
		z-index: -1;
		transition: rotate 200ms ease-out;
	}

	&::before {
		opacity: 0.6;
		scale: 0.98;
	}

	&::after {
		opacity: 0.3;
		scale: 0.94;
	}
}

.cabinet__item:not(:has(.disabled)) {
	position: relative;

	&::before {
		rotate: -3deg;
	}

	&::after {
		rotate: -6deg;
	}
}

.cabinet__item .fav {
	width: 18px;
	aspect-ratio: 1;
	position: absolute;
	top: 0;
	right: 0.4rem;
}

.cabient-meds__title {
	font: inherit;
	grid-area: title;
}

.cabinet-placeholder,
.cabinet-dose {
	grid-area: dose;
	justify-self: end;
}

.cabinet-placeholder {
	background-image: repeating-linear-gradient(
		-45deg,
		var(--medium-light-gray),
		var(--bubble) .09375rem,
		var(--bubble) .34375rem);
	border: 1px solid var(--dark);
	border-radius: 50vw;
	width: 100%;
	height: 2.375rem;
}

.cabinet-checkbox {
	grid-area: checkbox;
}

.cabinet-checkbox .toggle {
	padding: 0;
}

.cabinet-dose-counter {
	display: grid;
	grid-template-columns: repeat(3, max-content);
	align-items: center;
}

.cabinet-dose__counter {
	background-color: var(--black);
	border-radius: 0.8rem;
	line-height: 1;
	display: inline-grid;
	justify-content: center;
	align-items: center;
	width: 38px;
	aspect-ratio: 1;
	padding: 0.6rem;
}

.cabient-dose__input {
	@include mixins.input-element-reset;
	color: var(--page-color);
	width: 50px;
	text-align: center;
	padding: 0.8rem;
}

.cabinet-date {
	grid-area: date;
}

.cabinet-date__input {
	@include mixins.input-element-reset;
	color: var(--page-color);
	font-size: 0.85rem;
	font-weight: 500;
	width: max-content;
	cursor: pointer;
}

.cabinet-checkbox__input,
.cabinet-checkbox__text,
.cabient-dose__subtitle,
.cabinet-date__subtitle {
	@include mixins.visually-hidden;
}

.cabinet-dose.disabled,
.cabinet-placeholder.disabled,
.cabinet-instruction.disabled,
.cabinet-date.disabled,
.cabient-dose__input:disabled,
.cabinet-date__input:disabled {
    filter: blur(0.115625rem);
}
