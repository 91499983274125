/*
 * Buttons
 */
.btn {
    color: var(--black);
    line-height: 1;
    text-transform: uppercase;
    display: inline-block;
}

.btn--default {
    background-color: var(--accent);
    font-size: 0.82em;
    font-weight: 700;
    border-radius: 50vw;
    display: grid;
    grid-template-columns: max-content 30px;
    column-gap: 0.2em;
    padding: 1em;
}

.btn__icon-decore {
    width: 26px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    align-self: center;
    justify-self: end;
    transition: transform 280ms ease-in-out;
}

.btn__icon-decore__line {
    background-color: currentColor;
    border-radius: 0.25rem;
    width: 16px;
    height: 0.125rem;
    transform: translate(0.28rem, 0.0625rem);
}

.btn__icon-decore__arrow {
    width: 10px;

    &::before,
    &::after {
        content: '';
        background-color: currentColor;
        border-radius: 0.25rem;
        display: block;
        width: inherit;
        height: 0.125rem;
    }

    &::before {
        transform: rotate(45deg) translateX(-0.16rem);
    }

    &::after {
        transform: rotate(-45deg) translate(-0.16rem);
    }
}

.btn--default:hover .btn__icon-decore {
    transform: translateX(6px);
}

.btn--edit-small {
	background-color: var(--medium-light-gray);
	color: var(--black);
    border-radius: 50vw;
    display: grid;
    place-content: center;
    width: 20px;
    height: 20px;
}

.btn--delete,
.btn--cancel {
    color: var(--page-color);
    font-family: sans-serif;
    font-size: 0.78rem;
    font-weight: 600;
    align-self: center;
    box-shadow: 0 1px 0 0 currentColor;
}

.btn--view {
	background-color: var(--page-color);
	font-size: 0.68em;
	font-weight: 600;
	border-radius: 50vw;
	width: max-content;
	margin: 0 0.4em;
	padding: 0.8em 1.2em;
}

.btn-container {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    gap: 1rem 2rem;
    margin-top: calc(1.2rem + 0.75rem);
}
