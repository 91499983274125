@use 'mixins';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

::selection {
    background-color: var(--selection-bg);
    color: var(--selection-color);
}

html {
    @include mixins.hide-scrollbar;
	font-family: 'Inter', sans-serif;
    overflow-x: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: var(--page-bg);
    color: var(--page-color);
    min-height: 100dvh;
	max-width: 34rem;
    margin: 0 auto;
    padding: 1.5rem 0.7rem 4rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    line-height: 1;
    margin: 0;
    padding: 0;
}

h1,
h2 {
    font-size: clamp(1.8rem, 4vw, 3.3rem);
}

a {
    color: inherit;
    font: inherit;
    text-decoration: none;
}

ol {
    list-style: decimal-leading-zero;
}

button {
    @include mixins.button-reset;
}

button:disabled {
	cursor: default;
}

abbr {
	text-decoration: none;
}

dd {
    margin: 0;
}

@media (prefers-reduced-motion: reduce) {
    *,
    ::after,
    ::before {
        animation-delay: -1ms !important;
        animation-duration: 1ms !important;
        animation-iteration-count: 1 !important;
        background-attachment: initial !important;
        scroll-behavior: auto !important;
        transition-duration: 0s !important;
        transition-delay: 0s !important;
    }
}
