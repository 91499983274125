:root {
	--selection-bg: #fff;
	--selection-color: #000;

    --page-bg: #181d31;
    --page-color: #d5d5d5;

	--white: #fff;
	--lighter-gray: #d5d5d5;
	--light-gray: #b9b9b9;
	--medium-light-gray: #938b8b;
	--medium-gray: #717171;
	--dark-gray: #423b3d;
	--light-pink: #ffd9e4;
	--pink: #ffb1c9;
	--dark-red: #4f1629;
	--red: #c54040;
	--accent: #f1d00a;
	--green: #16d916;
	--dark: #252222;
	--bubble: #3A3845;
	--black: #000;

	/* Misc */
	--calendar: var(--black);
	--tabs-bg: var(--black);
	--tabs-selected: var(--lighter-gray);
	--tabs-selected-color: var(--black);
    --select-arrow-dark: url('/assets/arrow-down-black.svg');
    --select-arrow-light: url('/assets/arrow-down-white.svg');
	--default-border-radius: 1rem;

	--glass-bg: #221f1f70;
	--glass-btn: rgba(244, 237, 237, 0.373);
	--glass-btn-color: #e1dfdf;
}

@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: dark;
    }
}
