@use './mixins';

.calendar {
	background-color: var(--calendar);
	border-radius: var(--default-border-radius);
	display: grid;
	row-gap: 1rem;
	grid-template-columns: 100%;
	padding: 1rem 0.4rem;
}

.calendar__table {
	width: 100%;
	table-layout: fixed;
	border-collapse: collapse;
	border-spacing: 0;
	height: calc(100vh - 5.5rem - 64px - 4rem - 35px - 1.5rem);
}

.calendar-collection .calendar__table {
	height: calc(100vh - 5.5rem - 64px - 4rem - 35px - 2rem - 88px);
}

.calendar__table td,
.calendar__table th {
	margin: 0;
	padding: 0;
}

.calendar__day {
	font-size: 0.8rem;
	border-bottom: 1px solid var(--dark-gray);
	padding-block-end: 0.5em !important;
}

.calendar__day:not(:last-child) {
	border-right: 1px solid var(--dark-gray);
}

.calendar__row:not(:last-child) {
	box-shadow: 0 1px 0 0 var(--dark-gray);
}

.calendar__row td:not(:last-child) {
	box-shadow: 1px 0 0 0 var(--dark-gray);
}

.calendar__nav {
	display: grid;
	grid-template-columns: 5.8rem 8.2rem;
	gap: 1rem;
	width: fit-content;
	margin-left: auto;
	padding: 0 0.6rem;
}

.calendar__select {
	background-image: var(--select-arrow-light);
	background-position: calc(100% - 1em) 50%;
	color: var(--white);
	font-size: 0.84rem;
	font-weight: 600;
	padding: 0.7em 1.2em;
}

.calendar__cell {
	font-size: 0.65rem;
	font-weight: 600;
	line-height: 1;
	text-align: center;
	display: grid;
	grid-template-rows: repeat(2, 1fr);
	row-gap: 1rem;
	justify-items: center;
	align-items: center;
	width: 100%;
	height: calc((100vh - 300px) / 6);
	min-height: 8lh;
	padding: 0.2em 0.5em 0.5em;

}
.calendar__cell.is-dimmed {
	opacity: 0.3;
	filter: grayscale(1);
	// display: none;
}


.calendar__cell.is-today .calendar__cell-day,
.calendar__cell.is-selected .calendar__cell-day {
	color: var(--black);
	border-radius: 50%;
	display: grid;
	place-items: center;
	width: 1.125rem;
	aspect-ratio: 1;
	transition: background-color 250ms;
	will-change: background-color;
}

.calendar__cell.is-selected .calendar__cell-day {
	background-color: var(--accent);
}

.calendar__cell.is-today .calendar__cell-day {
	background-color: bisque;
}

.calendar__cell.is-highlighted .calendar__cell-day {
	background-color: rebeccapurple;
	color: #fff;
}

.calendar__cell-count {
	font-size: 0.78rem;
}

.calendar__cell-dot {
	background-color: var(--red);
	border-radius: 50%;
	width: 0.4rem;
	aspect-ratio: 1;
}


/*
** ALL CALENDAR COLLECTION (MOOD, WEIGHT, POSITIVE, MEDICIN PER DAY)
*/
.calendar-collection {
	--type-meds-bg: #8B5DFF;
	--type-spirit-bg: #FF6500;
	--type-mood-bg: #EFB6C8;
	--type-weight-bg: #3D3BF3;
	--type-radius: 0.6rem;
	--type-border-size: 4px;
}

.calendar-collection__status {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5em;
}

.calendar-collection__status__item {
	background-color: #000;
	border-radius: 50vw;
	font-size: 0.8rem;
	font-weight: 600;
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	align-items: center;
	column-gap: 0.5rem;
	padding: 0.5em 1.125em;
	opacity: 0.5;
}

.calendar-collection__status__item svg {
	width: 1.2rem;
}

.calendar-collection__status__item.is-checked {
	color: var(--green);
	opacity: 1;
}

[data-presentation-type="meds"],
[data-cell-type="meds"] {
	background-color: var(--type-meds-bg);
}

[data-presentation-type="spirit"],
[data-cell-type="spirit"] {
	background-color: var(--type-spirit-bg);
}

[data-presentation-type="mood"],
[data-cell-type="mood"] {
	background-color: var(--type-mood-bg);
}

[data-presentation-type="weight"],
[data-cell-type="weight"] {
	background-color: var(--type-weight-bg);
}

.calendar-cell__wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 0.3rem;
	justify-content: center;
	margin-top: auto;
}

.calendar-cell--all-item {
	display: block;
	border-radius: 50%;
	width: 0.6em;
	aspect-ratio: 1;
}

.calendar-collection h3 {
	font-size: 1rem;
}

.calendar-collection__mood {
	font-size: 0.88rem;
	display: flex;
	column-gap: 0.5rem;
	align-items: center;
	margin-block-start: 1rem;
}

.calendar-collection .calendar-presentation {
	>*+* {
		background-color: var(--dark);
		border-radius: var(--type-radius);
		padding: 0.8em;
		margin-block-start: 0.75em;
	}

	ul {
		font-size: 0.88rem;
		list-style-type: circle;
		padding-inline-start: 1.7rem;
	}

	ul > * + * {
		margin-block-start: 0.5rem;
	}

	p {
		font-size: 0.88rem;
	}
}

.calendar-collection [data-presentation-type="meds"] {
	border-left: var(--type-border-size) solid var(--type-meds-bg);
	border-top-left-radius: var(--type-radius);
	border-bottom-left-radius: var(--type-radius);
}

.calendar-collection [data-presentation-type="spirit"] {
	border-left: var(--type-border-size) solid var(--type-spirit-bg);
	border-top-left-radius: var(--type-radius);
	border-bottom-left-radius: var(--type-radius);
}

.calendar-collection [data-presentation-type="mood"] {
	border-left: var(--type-border-size) solid var(--type-mood-bg);
	border-top-left-radius: var(--type-radius);
	border-bottom-left-radius: var(--type-radius);
}

.calendar-collection [data-presentation-type="weight"] {
	border-left: var(--type-border-size) solid var(--type-weight-bg);
	border-top-left-radius: var(--type-radius);
	border-bottom-left-radius: var(--type-radius);
}


/*
** PRESENTATION
*/
.calendar-presentation {
	@include mixins.scrollbar-style;

	background-color: var(--bubble);
	color: var(--white);
	border-radius: var(--default-border-radius);
	width: calc(100% - 1.4rem);
	max-height: calc(100vh - 2.5rem);
	padding: 0 0.85em 1.5em;
	position: fixed;
	left: 0.7rem;
	bottom: 1rem;
	visibility: hidden;
	transform: translateY(100%);
	transition:
		transform 280ms ease-in-out,
		visibility 0ms 340ms;
	will-change: visibility, transform;
	pointer-events: none;
	overflow-y: scroll;
	overscroll-behavior: contain;
	z-index: 22;
}

.is-open.calendar-presentation {
	transition: visibility 0ms, transform 420ms ease-in-out;
	visibility: visible;
	transform: translateY(0);
	pointer-events: auto;
}

.calendar-presentation__header {
	background-color: var(--bubble);
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	column-gap: 1rem;
	padding: 1.5em 0;
	position: sticky;
	top: 0;
}

.calendar-presentation__close-btn-icon {
	display: grid;
	grid-template-columns: max-content;
	place-items: center;
	width: 1.2rem;
	aspect-ratio: 1;

	&::before,
	&::after {
		content: '';
		grid-area: 1 / -1;
		background-color: var(--white);
		border-radius: 4px;
		display: block;
		width: 100%;
		height: 2px;
	}

	&::before {
		transform: rotate(45deg);
	}

	&::after {
		transform: rotate(-45deg);
	}
}

.calendar-presentation__title,
.calendar-presentation__msg {
	@include mixins.title-small;
	margin-inline-start: 0.4em;
}

.calendar-presentation__title {
	font-size: 1.125rem;
	text-transform: capitalize;
}

.calendar-presentation__logg {
	font-size: 0.875rem;
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: 0.3em 0.66em;
}

.calendar-presentation__logg--meds {
	grid-template-columns: max-content 1fr auto;
}

.calendar-presentation__bubble-label {
	@include mixins.label-style;
	font-weight: 700;
}

.calendar-presentation__bubble-content {
	white-space: pre-wrap;
}

@include mixins.mq(medium) {
	.calendar {
		padding: 1rem;
	}

	.calendar-presentation {
		padding-inline-start: 1.5em;
		padding-inline-end: 1.5em;
	}
}
