@use './mixins';

.startpage {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: clamp(0.52rem, 2vw, 1.5rem);
}

.startpage__title {
    font-size: 1rem;
    font-weight: 600;
    padding: 0.4em 1em;
	grid-column: 1/-1;
}

.startpage__bubble {
    border-radius: 1.4rem;
    min-height: 9rem;
    display: grid;
    row-gap: 1.125em;
    grid-template-rows: max-content 1fr;
    padding: 2rem;
}

.startpage__bubble-title {
    font-size: 0.94rem;
    font-weight: 700;
}

.startpage__inner {
    font-size: 0.9rem;
    align-self: end;
}

.startpage ol {
    @include mixins.list-reset;

    > * + * {
        margin-top: 0.35em;
    }
}

.startpage__inner-mood .mood__icon {
    --size: 2rem;
	background-color: var(--accent);
}

.startpage__inner-mood .mood__label {
	display: block;
	margin-top: 0.5rem;
}

.startpage--summary .startpage__bubble {
    background-color: var(--bubble);
    color: var(--page-color);
    filter: drop-shadow(3px 3px 3px rgba(149, 138, 138, 0.05));
}

.startpage--summary .startpage__bubble:nth-child(3) {
	@include mixins.word-wrap;
	grid-row: 2 / 4;
}

.startpage--summary .startpage__bubble:last-of-type {
	grid-column: 1 / -1;
	max-width: 100%;
}
