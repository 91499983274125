@use './mixins';

.tab-list {
	@include mixins.scrollbar-style;

    --rounded: 1em;
    --spacing: 0.28em;
    --time: 400ms;
    --ease: ease-in-out;

    background-color: var(--tabs-bg);
    color: var(--white);
    border-radius: var(--rounded);
    display: flex;
    flex-wrap: nowrap;
    list-style: none;
    max-width: 40em;
    margin: 0 auto;
    margin-bottom: 1.5rem;
    padding: var(--spacing);
    position: relative;
    overflow-x: scroll;
    overflow-y: hidden;

    > :first-child {
        margin-left: auto;
    }

    > :last-child {
        margin-right: auto;
    }

    &::before {
        content: '';
        background-color: var(--tabs-selected);
        border-radius: calc(var(--rounded) - var(--spacing));
        position: absolute;
        width: calc(var(--w) * 1px);
        height: calc(100% - (var(--spacing) * 2));
        left: 0;
        transform: translateX(calc(var(--l) * 1px));
    }

    &.transition::before {
        transition-property: width, transform;
        transition-duration: var(--time);
        transition-timing-function: var(--ease);
    }
}

.tab {
    font-size: 0.85rem;
    font-weight: 600;
    display: block;
    padding: 1.3em 1.6em;
    position: relative;
    transition: color 250ms var(--ease);
}

.tab.is-selected {
    color: var(--tabs-selected-color);
}
