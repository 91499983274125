@use 'mixins';

.site__footer {
	width: calc(100% - 2rem);
	position: fixed;
	left: 1rem;
	bottom: 1rem;
	z-index: 10;
}

.signout-success {
    display: grid;
    place-content: center;
    min-height: 100vh;
    position: fixed;
    inset: 0;
}

.section__title {
    @include mixins.visually-hidden;
}

.section__stats {
    @include mixins.title-small;
    margin-top: 0;
}

.section__stats > * {
    display: block;
}

.section dt {
	@include mixins.label-style;
}



/*
 * Utility styling
 */
.visually-hidden {
    @include mixins.visually-hidden;
}

.u-error-msg {
    color: var(--red);
    font-weight: 600;
    display: block;
    margin-bottom: 1em;
}

.u-cell-span {
    grid-column: 1/-1;
}

.u-dd-note {
    white-space: pre-wrap;
}

.fav {
    align-self: start;
    fill: var(--accent);
    width: 18px;
    aspect-ratio: 1;
}

.u-slider-wrapper {
    overflow: hidden;

    @include mixins.scrollbar-style;
}

.u-slider {
    display: flex;
    gap: clamp(0.52rem, 2vw, 2rem);
    list-style-type: none;
    margin: 0;
    padding: 1em 1em;
    overflow-x: scroll;
    overflow-y: hidden;
    overscroll-behavior-x: contain;
    scroll-snap-type: x mandatory;
    cursor: grab;
}

.u-slider > * {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
    flex: 0 0 auto;
    scroll-snap-align: center;
    user-select: none;
}
