@use 'mixins';

.graph-container {
    --h: min(100dvh - 12.5rem, 500px);
	--_br: 14px;

    background-color: var(--bubble);
    color: var(--white);
    border-radius: var(--_br);
    padding: 0.5em;
	position: relative;
}

.graph-container h1 {
    font-size: 1.85rem;
    font-weight: 400;
    padding: 0.2rem 0.5rem;
}

.graph-container h1 + p {
    font-size: 0.96rem;
    font-weight: 400;
    margin: 0.2rem 0.5rem 1rem;
}

.graph-container__filter {
	background-color: var(--page-bg);
	border-bottom-left-radius: inherit;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 0.5rem;
	padding-left: inherit;
	padding-bottom: inherit;
	position: absolute;
	top: 0;
	right: 0;
	isolation: isolate;

	&::before,
	&::after {
		content: '';
		border: 0 solid var(--page-bg);
		border-width: var(--_br) var(--_br) 0 0;
		border-top-right-radius: calc(var(--_br) * 2);
		width: var(--_br);
		height: var(--_br);
		position: absolute;
		z-index: -1;
	}

	&::before {
		top: calc(var(--_br) * -1);
		right: calc(100% - var(--_br));
	}

	&::after {
		top: calc(100% - var(--_br));
		right: calc(var(--_br) * -1);
	}
}

.graph-container__select {
    background-color: var(--accent);
	color: var(--black);
	background-image: var(--select-arrow-dark);
    background-position-x: calc(100% - 0.5em);
    background-size: 0.875rem;
    border-radius: 50vw;
    font-size: 0.8rem;
    line-height: 1.5;
	text-align: center;
    width: max-content;
    padding: 0.55em 1.5em 0.4em 0.8em;
}

.diagnostic-level-wrapper {
    position: sticky;
    left: 0;
}

.diagnostic-level {
    width: 1.5rem;
    height: var(--h);
    position: relative;
}

.diagnostic-level__item {
    font-size: 0.65rem;
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 0.3em;
    width: 100%;
    height: calc(var(--l) * 100%);
    position: absolute;
    bottom: 0;

    &::after {
        content: '';
        background-color: currentColor;
        display: block;
        width: 96vw;
        height: 1px;
		opacity: 0.33;
    }
}

.diagnostic-level__item-number {
    color: inherit;
    margin-top: -0.5rem;
}

.scoring {
    @include mixins.hide-scrollbar;
    @include mixins.list-reset;
    display: flex;
	column-gap: 1px;
    padding-top: 1rem;
	padding-bottom: 1.5rem;
    overflow-x: scroll;
    position: relative;
}

.graph {
	color: var(--light-gray);
	font-size: 0.6rem;
	font-weight: 600;
	text-align: center;
	width: 2rem;
	flex-shrink: 0;
    position: relative;
}

.graph__bar {
	border-top: 3px solid var(--accent);
	background-image: linear-gradient(to top, transparent -10%, var(--accent) 120%);
	background-size: 100% var(--h);
	background-position: 0 100%;
    width: 100%;
	height: calc(100% * var(--delta));
	position: absolute;
	bottom: 0;
	transition: height 500ms;
}

.graph__score,
.graph__date {
	display: block;
	width: 100%;
	padding: 0.3rem 0;
	position: absolute;
}

.graph__score {
	bottom: 100%;
}

.graph__date {
	top: 100%;
}
