@use 'sass:map';

/*
 * Media querys
 */
$breakpoints: (
    small: 500px,
    medium: 700px,
	large: 900px
);

@mixin mq($key) {
    $size: map.get($breakpoints, $key);

    @media (min-width: $size) {
        @content;
    }
}

/*
 * Everything else
 */
@mixin visually-hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    position: absolute;
    overflow: hidden;
    white-space: nowrap;
}

@mixin list-reset {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin details-marker-reset {
    cursor: pointer;
    list-style: none;

    &::marker {
        display: none;
    }

    &::-webkit-details-marker {
        display: none;
    }
}

@mixin button-reset {
    background-color: transparent;
    color: inherit;
    border: none;
    border-radius: none;
    font: inherit;
    line-height: 1;
	text-align: inherit;
    display: inline-block;
    margin: 0;
    padding: 0;
    outline: none;
    cursor: pointer;
}

@mixin input-element-reset {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font: inherit;
    width: 100%;
    margin: 0;
    padding: 0;
    cursor: text;
    outline: none;
    -webkit-appearance: none;
}

@mixin select-element-reset {
    background-color: transparent;
    color: inherit;
    border: none;
    border-radius: 0;
    font: inherit;
    width: 100%;
    outline: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    cursor: pointer;
}

@mixin label-style {
    font-size: 0.875rem;
    font-weight: 600;
    margin-bottom: 0.125rem;
}

@mixin title-small {
    font-size: 0.93rem;
    font-weight: 600;
}

@mixin hide-scrollbar {
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
        width: 0;
        height: 0;
    }
}

@mixin scrollbar-style {
	@supports not selector(::-webkit-scrollbar) {
		scrollbar-width: thin;
		scrollbar-color: var(--lighter-gray) transparent;
	}

	&::-webkit-scrollbar {
		background-color: var(--dark-gray);
		border-radius: 100vw;
		width: 0.22em;
		height: 0.22em;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--dark-gray);
		border-radius: 100vw;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--lighter-gray);
		border-radius: 100vw;
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: var(--accent);
	}
}

@mixin word-wrap {
	overflow-wrap: break-word;
	word-wrap: break-word;
	word-break: break-all;
	word-break: break-word;
	hyphens: auto;
}
