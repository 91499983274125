.loader {
    display: grid;
    place-content: center;
    height: 50vh;
}

.loader--calendar {
    height: 20vh;
}

.loader--editform {
    height: 80vh;
}

.heart {
    --_const: 50px;
    --_shape: calc(var(--_const) * 0.6);
    --_h: calc(var(--_const) * 0.2);
    width: var(--_const);
    height: var(--_h);
    position: relative;
    animation: beating 1.2s infinite cubic-bezier(.215, .61, .355, 1);

    &::before,
    &::after {
        content: '';
        background: var(--light-pink);
        border-radius: var(--_shape) var(--_shape) 0 0;
        width: var(--_shape);
        height: var(--_const);
        position: absolute;
        left: var(--_shape);
        top: 0;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
    }

    &::after {
        left: 0;
        transform: rotate(45deg);
        transform-origin: 100% 100%;
    }
}

@keyframes beating {
    0%   { transform: scale(0.95); }
    5%   { transform: scale(1.1); }
    39%  { transform: scale(0.85); }
    45%  { transform: scale(1); }
    60%  { transform: scale(0.95); }
    100% { transform: scale(0.9); }
}


/*
** Startpage loader
*/
.dot-loader {
    --_s: 6px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    column-gap: calc(var(--_s) * 1.1);
    margin: 2.65rem 0;
}

.dot-loader-shape {
    background-color: var(--accent);
    border-radius: 50%;
    width: var(--_s);
    aspect-ratio: 1;
    opacity: 0;
    animation: dot-loader 600ms infinite alternate;

    &:nth-child(2) {
        animation-delay: 200ms;
    }

    &:nth-child(3) {
        animation-delay: 400ms;
    }
}

@keyframes dot-loader {
    to { opacity: 1; }
}
