@use 'mixins';

form ::placeholder {
    color: var(--light-gray);
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

legend {
    font-weight: 500;
    font-size: 0.9rem;
    text-transform: uppercase;
    width: 100%;
    padding-bottom: 1.3em;
}

textarea {
    @include mixins.hide-scrollbar;
    height: 100%;
    resize: none;
    white-space: pre-wrap;
}

select {
    @include mixins.select-element-reset;
    background-image: var(--select-arrow-light);
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: 100% 50%;
}

.form-wrapper {
    --space: 1em;
}

.form-wrapper--dailymeds {
    max-width: 75rem;
}

.form__layout {
    color: var(--page-color);
}

.form__layout h2 {
	@include mixins.label-style;
	grid-column: 1/-1;
	margin: 1rem;
}

.form__layout > * + * {
	margin-top: 0.8rem;
}

.form__layout-check {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    padding: var(--space) 0;
}

.form__cell-grid {
	background-color: var(--bubble);
	border-radius: var(--default-border-radius);
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 0.5rem;
}

.field {
	background-color: var(--bubble);
	border-radius: var(--default-border-radius);
    font-family: sans-serif;
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 1.4em;
    padding: var(--space);
}

.field:has([required]) {
	.field__label {
		display: grid;
		grid-template-columns: repeat(2, max-content);
		column-gap: .5rem;
	}

	.field__label::after {
		content: '(required)';
		display: block;
	}
}

.field__input {
    @include mixins.input-element-reset;
    font-size: 0.94rem;
}

.field__label {
    font-size: 0.71rem;
    font-weight: 700;
    text-transform: uppercase;
}

.form__layout .checkbox {
    display: inline-block;
    flex: 1 0 auto;
}

.form__note,
.form__instructions {
	padding: 1em;
}

.form__note h3 {
	margin-bottom: 0;
}

.form__instructions {
	font-weight: 600;
}


/*
 * Checkbox
 */
[type="checkbox"] {
    @include mixins.visually-hidden;
}

.toggle {
    font-size: 0.9em;
    font-weight: 600;
    letter-spacing: 0.02em;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    column-gap: 0.7em;
    align-items: center;
    padding: 0.3em 1em 0.3em 0.3em;
    cursor: pointer;
}

.toggle-indicator {
    background-color: var(--accent);
    border-radius: 0.4rem;
    display: grid;
    grid-template-columns: max-content;
    grid-template-areas: "stack";
    place-content: center;
    width: 1.7rem;
    aspect-ratio: 1;

    &::before,
	&::after {
        content: '';
        grid-area: stack;
        background-color: var(--black);
        display: block;
        width: 10px;
        height: 2px;
		transition: rotate 200ms ease-out;
    }

	&::before {
		rotate: 45deg;
	}

	&::after {
		rotate: -45deg;
	}
}

:checked+.toggle .toggle-indicator::before,
:checked+.toggle .toggle-indicator::after {
    rotate: 0deg;
}


/*
 * Radio buttons
 */
.field-radio__input {
    @include mixins.visually-hidden;
}

.field-radio__label {
    display: grid;
    grid-template-columns: max-content auto;
    grid-template-areas: "icon content";
    align-items: center;
    gap: .5rem;
    cursor: pointer;
    margin-top: .55rem;

    &::before,
    &::after {
        content: '';
        border-radius: 50%;
        display: inline-block;
        grid-area: icon;
    }

    &::before {
        border: 1px solid;
        width: 20px;
        height: 20px;
    }

    &::after {
        background-color: var(--white);
        justify-self: center;
        width: 14px;
        height: 14px;
        opacity: var(--checked, 0);
        transition: opacity 90ms ease;
    }
}

:checked + .field-radio__label {
    --checked: 1;
}


/*
 * Mood form
 */
.form-wrapper--mood {
    max-width: 56rem;
}

.form--emoticon [type="radio"] {
    @include mixins.visually-hidden;
}

.form--emoticon svg {
    fill: var(--lighter-gray);
    width: 2.5rem;
    padding: 0.25rem;
}

.form--emoticon__grid {
    @include mixins.list-reset;
	background-color: var(--bubble);
	border-radius: var(--default-border-radius);
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, auto));
    gap: 2rem 1rem;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
	padding: 1rem 0.5rem;
    text-align: center;
}

.form--emoticon__tagname {
    font-size: 0.7rem;
    text-transform: uppercase;
    display: block;
}

:checked + .form--emoticon__label svg {
    background-color: var(--white);
    border-radius: 50%;
    fill: var(--black);
}


/*
 * Survey
 */
.form--survey {
	counter-reset: section;
	font-size: 0.88rem;
}

.form--survey h3 {
	margin-bottom: 1.3rem;
}

.field--survey h3 {
	font-size: 0.74rem;
	font-weight: 700;
	line-height: 1.3;
	text-transform: uppercase;
}

.field--survey__tag {
	background-color: var(--black);
	color: var(--white);
	border-radius: 50vw;
	font-size: 0.7rem;
	font-weight: 500;
	width: max-content;
	margin-top: 0;
	padding: 0.4em 0.8em;
}

.field--survey {
	display: block;
	position: relative;
	padding: var(--space) 3rem var(--space) var(--space);

	&::before {
		counter-increment: section;
		content: counter(section, decimal-leading-zero);
		background-color: var(--white);
		color: var(--black);
		font-size: 0.7rem;
		font-weight: 500;
		border-radius: 50%;
		width: 12px;
		height: 12px;
		display: grid;
		place-content: center;
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		padding: 0.5rem;
	}
}


/*
 * Positive things form
 */
.positive-form > * {
	margin: 0;
}

.field-radio--good {
	border-radius: var(--default-border-radius) var(--default-border-radius) 0 0;
}

.field-radio--bad {
	border-radius: 0 0 var(--default-border-radius) var(--default-border-radius);
}

.positive-form .field--date {
	margin-bottom: 1rem;
}

.positive-form .field-radio__label {
	color: var(--white);
}

.positive-form .field-radio {
	background-color: var(--bubble);
    padding: var(--space);
}

.positive-form .field--textarea {
	border-radius: 0;
}


/*
 * Login form
 */
.form-wrapper--login {
    max-width: 24rem;
    width: 100%;
    margin-top: 7rem;
}

.form--login legend {
    @include mixins.visually-hidden;
}

.form--login .btn {
    margin-top: 2rem;
}
