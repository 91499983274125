@use './mixins';

/*
 * Base styling
 */
.accordion {
	background-color: var(--bubble);
	color: var(--page-color);
	border-radius: var(--default-border-radius);
    font-size: 0.875rem;

	&:not(:first-child) {
		margin-top: 0.5rem;
	}
}

.accordion__name {
    @include mixins.details-marker-reset;
	font-size: inherit;
    font-weight: 600;
    line-height: 1.5;
    width: 100%;
    margin: 0;
    position: relative;
    transition: color 350ms ease;
    will-change: color;

	button {
		width: 100%;
		padding: 1.25rem 0.625rem;
	}
}

.accordion__content[aria-hidden="false"] {
	grid-template-rows: 1fr;
}

.accordion__content {
    line-height: 1.36;
	display: grid;
	grid-template-rows: 0fr;
	transition: grid-template-rows 280ms;
    padding: 0 0.625rem;

	> div {
		overflow: hidden;
	}
}

/*
 * List styling
 */
.accordion--list dl {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 1.2em;
}

.accordion--list dd {
    font-family: sans-serif;
}

.accordion--list .fav {
    width: 1em;
	vertical-align: text-bottom;
    margin-left: 0.5em;
}

.accordion--list .btn-container {
    padding-bottom: 1.5rem;
}

/*
 * Table styling
 */
.accordion--table {
	background-color: var(--bubble);
	border-radius: var(--default-border-radius);

	table {
		font-size: 0.8rem;
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		margin-top: 1em;
		margin-bottom: 1em;
	}

	td {
		padding: 0.34em 0;
	}

	th {
		text-align: left;
		padding-bottom: 0.3em;
	}

	th:nth-child(2) {
		text-align: center;
		width: 6ch;
	}

	th:nth-child(3) {
		text-align: center;
		width: 8ch;
	}

	tr:not(:last-of-type) {
		border-bottom: 1px dotted var(--dark-gray);
	}

	tr td:nth-child(2),
	tr td:nth-child(3) > * {
		text-align: center;
		margin-right: auto;
		margin-left: auto;
	}
}
